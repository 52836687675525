import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ContactForm from "../contact/ContactForm";

export class ContentContact extends Component {
  render() {
    return (
      <div className="container">
        <div class="col-lg-12 col-md-12 text-page-content">
          {this.props.content.title != null && this.props.content.sectionOrder === false && (
            <React.Fragment>
              <h1 className="text-page-header">{this.props.content.title}</h1>
              <div className="title-page-divider"></div>
            </React.Fragment>
          )}

          {ReactHtmlParser(ReactHtmlParser(this.props.content.content))}

          <ContactForm />

          {this.props.content.contentShowdate == true && (
            <p class="small">
              Data aktualizacji:
              {new Intl.DateTimeFormat("pl-PL", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(this.props.content.contentDate)}
            </p>
          )}
        </div>
      </div>
    );
  }
}

ContentContact.propTypes = {
  content: PropTypes.object,
};

export default ContentContact;
