import React, { useState, useEffect, Fragment } from "react";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from "reactstrap";
//import { accessToken, siteId, apiUrl, apiUrl2 } from "../../config";
import { clientConnect } from "../../client";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import ContentLoader from "react-content-loader";

const Header = (props) => {
  // const items = [
  //   {
  //     hash: '39D05D36-30F5-4F6B-9C5B-C76541AB823B',
  //     altText: 'Slide 1',
  //   }
  // ];

  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log("1");
    // setIsLoading(true);
    // async (x) => await clientConnect("api/mainslider/list", null).then((response) => setData(response));
    async function fetchData() {
      return await clientConnect("api/mainslider/list", null);
    }
    fetchData()
      .then((response) => setItems(response))
      .finally(() => setIsLoading(false));
    // setData(clientConnect("api/mainslider/list", null));
    // console.log(items);
    // setIsLoading(false);
    // console.log("2");
    // fetch(`${apiUrl}api/mainslider/list`, { headers: { "X-BBCMS-TOKEN": accessToken, "X-BBCMS-SITE": siteId } })
    //   .then((response) => response.json())
    //   .then((json) => setItems(json))
    //   .catch((error) => console.error(error))
    //   .finally(() => setIsLoading(false));
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, i) => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={i}>
        <div style={{ maxHeight: "675px", textAlign: "center", minHeight: "675px" }}>
          <div style={{ position: "absolute", left: "50%" }}>
            <div style={{ position: "relative", left: "-50%" }}>{ReactHtmlParser(ReactHtmlParser(item.content))}</div>
          </div>
          {item.mode === 1 && (
            <img src={process.env.REACT_APP_CDN1+`/imageasset/get/${item.imageAssetHash}?v=${item.version}`} alt={item.altText} width={1920} height={600} />
          )}
          {item.mode === 3 && (
            <img
              src={process.env.REACT_APP_CDN3+`/mainsliders/${props.siteHash}/${item.fileName}?v=${item.version}`}
              alt={item.altText}
              width={1920}
              height={600}
              className="img-fluid"
            />
          )}
          {item.mode === 4 && (
            <img
              src={process.env.REACT_APP_CDN4+`/mainsliders/${item.fileName}?v=${item.version}`}
              alt={item.altText}
              width={1920}
              height={600}
              className="img-fluid"
            />
          )}
        </div>
      </CarouselItem>
    );
  });

  return (
    <Fragment>
      {isLoading ? (
        <div className="text-center" style={{ maxHeight: "675px" }}>
          <ContentLoader viewBox="0 0 400 160" height={675} width={"100%"} backgroundColor="transparent">
            <circle cx="150" cy="86" r="5" />
            <circle cx="194" cy="86" r="5" />
            <circle cx="238" cy="86" r="5" />
          </ContentLoader>
        </div>
      ) : (
        <Fragment>
          {items !== undefined && items.length > 0 && (
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
              {slides}
            </Carousel>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Header;
