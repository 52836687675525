import React, { Component } from "react";
import { accessToken, siteId, apiUrl } from "../../config";
import Loading from "../shared/Loading";

export default class ContactForm extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      status: "",
      message: "",
      fields: { firstName: "", lastName: "", email: "", phone: "", message: "" },
      errors: {},
    };
  }

  async sendForm(model) {
    fetch(`${apiUrl}api/contact`, {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-BBCMS-TOKEN": accessToken,
        "X-BBCMS-SITE": siteId,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.status);
        if (response.status === "OK") {
          this.setState({ status: true, loading: false });
          this.resetForm();
        } else if (response.status === "Error") {
          this.setState({ status: false, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ status: false, loading: false });
      });
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if (fields["firstName"] === "") {
      formIsValid = false;
      errors["firstName"] = "Please enter your first name.";
    }

    //Name
    if (fields["lastName"] === "") {
      formIsValid = false;
      errors["lastName"] = "Please enter your last name.";
    }

    if (fields["message"] === "") {
      formIsValid = false;
      errors["message"] = "Please enter your message.";
    }

    //email
    if (fields["email"] === "") {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
    } else {
      if (
        !fields["email"].match(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    //email
    if (fields["phone"] === "") {
    } else {
      if (typeof fields["phone"] !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(fields["phone"])) {
          formIsValid = false;
          errors["phone"] = "Please enter only number.";
        } else if (fields["phone"].length <= 9) {
          formIsValid = false;
          errors["phone"] = "Please enter valid phone number.";
        }
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true }, () => {
      if (this.handleValidation()) {
        const model = {
          fname: this.state.fields.firstName,
          lname: this.state.fields.lastName,
          email: this.state.fields.email,
          phone: this.state.fields.phone,
          message: this.state.fields.message,
        };

        this.sendForm(model);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  resetForm = (e) => {
    Array.from(document.querySelectorAll("input")).forEach((input) => (input.value = ""));
  };

  render() {
    return (
      <div className="row justify-content-md-center" style={{ marginTop: "30px" }}>
        <div className="col-md-12">
          {this.state.status === false && this.state.loading === false && (
            <div className="alert alert-danger" role="alert">
              {this.state.message}
            </div>
          )}

          {this.state.status === true && this.state.loading === false && (
            <div className="alert alert-success" role="alert">
              Thank you for sending us a messages, we will reply soon!
            </div>
          )}

          {this.state.loading === false ? (
            <form onSubmit={this.handleSubmit}>
              <div className="form-row row justify-content-md-center">
                <div className="form-group col-md-6 mb-2">
                  <input
                    ref="firstName"
                    id="firstName"
                    type="text"
                    className="form-control"
                    placeholder="First name *"
                    onChange={this.handleChange.bind(this, "firstName")}
                    value={this.state.fields["firstName"]}
                  />
                  {this.state.errors.firstName && (
                    <div style={{ color: "red" }} className="mt-2">
                      <i className="fas fa-exclamation-triangle"></i> {this.state.errors["firstName"]}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row row justify-content-md-center">
                <div className="form-group col-md-6 mb-2">
                  <input
                    ref="lastName"
                    id="lastName"
                    type="text"
                    className="form-control"
                    placeholder="Last name *"
                    onChange={this.handleChange.bind(this, "lastName")}
                    value={this.state.fields["lastName"]}
                  />
                  {this.state.errors.lastName && (
                    <div style={{ color: "red" }} className="mt-2">
                      <i className="fas fa-exclamation-triangle"></i> {this.state.errors["lastName"]}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row row justify-content-md-center">
                <div className="form-group col-md-6 mb-2">
                  <input
                    ref="email"
                    id="email"
                    type="text"
                    className="form-control"
                    placeholder="E-mail address *"
                    onChange={this.handleChange.bind(this, "email")}
                    value={this.state.fields["email"]}
                  />
                  {this.state.errors.email && (
                    <div style={{ color: "red" }} className="mt-2">
                      <i className="fas fa-exclamation-triangle"></i> {this.state.errors["email"]}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row row justify-content-md-center">
                <div className="form-group col-md-6 mb-2">
                  <input
                    ref="phone"
                    id="phone"
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    onChange={this.handleChange.bind(this, "phone")}
                    value={this.state.fields["phone"]}
                  />
                  {this.state.errors.phone && (
                    <div style={{ color: "red" }} className="mt-2">
                      <i className="fas fa-exclamation-triangle"></i> {this.state.errors["phone"]}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row row justify-content-md-center">
                <div className="form-group col-md-6 mb-2">
                  <textarea
                    ref="message"
                    id="message"
                    className="form-control"
                    placeholder="Message *"
                    rows="5"
                    onChange={this.handleChange.bind(this, "message")}
                    value={this.state.fields["message"]}
                  />
                  {this.state.errors.message && (
                    <div style={{ color: "red" }} className="mt-2">
                      <i className="fas fa-exclamation-triangle"></i> {this.state.errors["message"]}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-row row justify-content-md-center mt-2 mb-4">
                <div className="form-group col-md-6">
                  <button className="btn btn-primary btn-block">SUBMIT</button>
                </div>
              </div>
            </form>
          ) : (
            <Loading loaded={this.state.loaded} />
          )}
        </div>
      </div>
    );
  }
}
