import React, { Component } from "react";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import SectionText from "./SectionText";
import SectionNews from "./SectionNews";
import SectionEventList from "./SectionEventList";
import SectionCalendar from "./SectionCalendar";
import SectionNewsletter from "./SectionNewsletter";
import SectionContact from "./SectionContact";
import SectionMap from "./SectionMap";
import { Element } from "react-scroll";
import { Container } from "reactstrap";

export class Section extends Component {
  styleSection = (background, margintop, marginbottom) => {
    return {
      backgroundColor: background,
      marginTop: `${margintop}px`,
      marginBottom: `${marginbottom}px`,
    };
  };

  rowStyles = () => {
    return {
      marginTop: "20px",
      marginBottom: "20px",
    };
  };

  render() {
    return (
      <>
        <Element id={this.props.sections.hash} name={this.props.sections.hash}>
          {this.props.sections.inContainer === true ? (
            <Container>
              <section
                style={this.styleSection(this.props.sections.background, this.props.sections.marginTop, this.props.sections.marginBottom)}
                className="content-section"
                key={this.props.sections.hash}
              >
                {this.props.sections.title !== null && this.props.sections.titleShow === true && (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-12 xs-text-center">
                        <h2 style={{ textAlign: "center" }}>{this.props.sections.title}</h2>
                      </div>
                    </div>
                  </React.Fragment>
                )}
                <div className={`row ${this.props.sections.isContentCentered && `justify-content-center`}`}>
                  {this.props.sections.sectionContents.map((sectionContent) =>
                    (() => {
                      switch (sectionContent.type) {
                        case "text":
                          return <SectionText sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        case "news":
                          return <SectionNews sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        case "event-list":
                          return <SectionEventList sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        case "calendar":
                          return <SectionCalendar sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        case "newsletter":
                          return <SectionNewsletter sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        case "contact":
                          return <SectionContact sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        case "map":
                          return <SectionMap sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                        default:
                          return <SectionText sectionContent={sectionContent} key={sectionContent.hash} />;
                          break;
                      }
                    })()
                  )}
                </div>
              </section>
            </Container>
          ) : (
            <section
              style={this.styleSection(this.props.sections.background, this.props.sections.marginTop, this.props.sections.marginBottom)}
              className="content-section"
              key={this.props.sections.hash}
            >
              {this.props.sections.title !== null && this.props.sections.titleShow === true && (
                <div className="row-fluid">
                  <div className="col-12 xs-text-center">
                    <h2 style={{ textAlign: "center" }}>{this.props.sections.title}</h2>
                  </div>
                </div>
              )}
              <div className={`row-fluid ${this.props.sections.isContentCentered ? `justify-content-center` : ``}`}>
                {this.props.sections.sectionContents.map((sectionContent) =>
                  (() => {
                    switch (sectionContent.type) {
                      case "text":
                        return <SectionText sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      case "news":
                        return <SectionNews sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      case "event-list":
                        return <SectionEventList sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      case "calendar":
                        return <SectionCalendar sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      case "newsletter":
                        return <SectionNewsletter sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      case "contact":
                        return <SectionContact sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      case "map":
                        return <SectionMap sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                      default:
                        return <SectionText sectionContent={sectionContent} key={sectionContent.hash} />;
                        break;
                    }
                  })()
                )}
              </div>
            </section>
          )}
        </Element>
      </>
    );
  }
}

export default Section;
