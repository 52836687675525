import React, { Component, Fragment } from "react";
// import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { clientConnect } from "../../client";
import { siteId } from "../../config";
import Moment from "react-moment";

export class NewsDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      news: [],
      loadingNews: true,
    };
  }

  componentDidMount() {
    this.populateNewsData(this.props.news);
  }

  render() {
    return (
      <Fragment>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            paddingTop: "30px",
          }}
        >
          {!this.state.loadingNews ? (
            <Fragment>
              <div className="row">
                <div className="col-12 xs-text-center">
                  <div className="h1" style={{ textAlign: "center" }}>
                    {this.state.news.title}
                  </div>
                  <h5 className="news-category text-center pb-2">
                    <a
                      href={`/${this.props.baseUrl.url}/category/${this.state.news.categoryUrl}`}
                    >
                      <i className="fa fa-sitemap"></i>
                      <span className="pl-2">
                        {this.state.news.categoryName}
                      </span>
                    </a>
                  </h5>
                </div>
              </div>
              {this.state.news.hasImageFull && (
                <>
                  {this.state.news.imageAssetMode === 1 && (
                    <img
                      src={
                        process.env.REACT_APP_CDN1 +
                        `/imageasset/get/${this.state.news.imageAssetFullHash}`
                      }
                      alt={this.state.news.title}
                      className="img-fluid"
                      layout="responsive"
                      width={1140}
                      height={375}
                    />
                  )}
                  {this.state.news.imageAssetMode === 3 && (
                    <img
                      src={
                        process.env.REACT_APP_CDN3 +
                        `/images/${process.env.REACT_APP_siteId}/${this.state.news.imageAssetFullFileName}`
                      }
                      alt={this.state.news.title}
                      width={1140}
                      height={375}
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                  {this.state.news.imageAssetMode === 4 && (
                    <img
                      src={
                        process.env.REACT_APP_CDN4 +
                        `/images/${process.env.REACT_APP_siteId}/${this.state.news.imageAssetFullFileName}`
                      }
                      alt={this.state.news.title}
                      width={1140}
                      height={375}
                      className="img-fluid"
                      loading="lazy"
                    />
                  )}
                </>
              )}
              <div className="row mt-3">
                <div className="col-md-12">
                  {ReactHtmlParser(this.state.news.content)}
                </div>
              </div>
              {this.state.news.tag !== "" && (
                <div className="row mt-3">
                  <div className="col-md-12">
                    <h3 className="member-tag text-center">
                      {this.state.news.tag}
                    </h3>
                  </div>
                </div>
              )}
              {this.state.news.newsShowDate === true && (
                <p class="small text-center">
                  Published:&nbsp;
                  <Moment format="DD.MM.YYYY">
                    {this.state.news.newsDate}
                  </Moment>
                </p>
              )}
              <h5 className="news-category text-center pb-2">
                <a
                  href={`/${this.props.baseUrl.url}/category/${this.state.news.categoryUrl}`}
                >
                  <span>News category: {this.state.news.categoryName}</span>
                </a>
              </h5>
              <hr />
              <div
                style={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  margin: "20px",
                }}
                className="text-center"
              >
                <a href={`/${this.props.baseUrl.url}`}>back to list</a>
              </div>
            </Fragment>
          ) : (
            <p className="text-center">Loading...</p>
          )}
        </div>
      </Fragment>
    );
  }

  async populateNewsData(url) {
    this.setState({
      news: await clientConnect("api/news/get", url),
      loadingNews: false,
    });
  }
}

export default NewsDetails;
