import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect, clientConnectCustom } from "../../client";
import Loading from "../shared/Loading";
import { accessToken, siteId, apiUrl2 } from "../../config";

export class MembersList extends Component {
  static displayName = MembersList.name;

  constructor(props) {
    super();
    this.state = {
      members: [],
      formFields: [],
      loadingMembers: true,
      loadingForm: true,
      status: true,
      message: "",
      fields: { country: "", industry: "" },
      errors: {},
    };
  }

  handleValidation() {
    //let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loadingMembers: true }, () => {
      if (this.handleValidation()) {
        const model = {
          country: this.state.fields.country,
          industry: this.state.fields.industry,
        };

        this.serachMembers(model);
      } else {
        this.setState({ loadingMembers: false });
      }
    });
  };

  async serachMembers(model) {
    await fetch(`${apiUrl2}api/catalog/search`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        "X-BBCMS-TOKEN": accessToken,
        "X-BBCMS-SITE": siteId,
      },
      body: JSON.stringify(model),
    })
      .then((response) => response.json())
      .then((json) => this.setState({ members: json.companies, loadingMembers: false }))
      .catch((err) => {
        this.setState({ loadingFrom: false });
      });

    // await axios
    //   .post("/api/token/gettoken", model)
    //   .then((response) => {
    //     if (response.data.guest === "OK") {
    //       this.props.history.push("/show");
    //     } else {
    //       this.setState({ members: false, message: response.data.message });
    //       this.setState({ loadingForm: false });
    //     }
    //   })
    //   .catch((err) => {
    //     this.setState({ loadingFrom: false });
    //   });
  }

  componentDidMount() {
    this.populateMemberListData();
    this.populateFieldsData();

    //document.getElementById("root").style.backgroundImage = `url(${process.env.PUBLIC_URL + '/images/bcgkNormal.jpg'})`;
    //document.getElementById("root").style.backgroundPosition = 'top center';
    //document.getElementById("root").style.backgroundRepeat = 'no-repeat';
  }

  renderMembers = (members, url) => {
    let inRow = 4;
    let Tabs = members.length / inRow;

    let membersList = new Array();

    function renderCols(members, tab, cols) {
      let start = tab * cols;
      let end = start + cols;

      if (end > members.length) {
        end = members.length;
      }

      var colsList = new Array();

      for (let j = start; j < end; j++) {
        colsList.push(
          <div className="col-md-3" key={`${tab}.${j}`}>
            <div className="member-box-wrapper">
              <div className="member-box">
                <div className="member-box-body">
                  {members[j].hasLogo ? (
                    <p className="text-center">
                      <a href={`/${url}/member/${members[j].url}`}>
                        <img src={`${apiUrl2}api/catalog/getlogo/${members[j].url}`} alt={members[j].name} className="img-fluid" />
                      </a>
                    </p>
                  ) : (
                    <p className="text-center mt-5">
                      <a href={`/${url}/member/${members[j].url}`}>
                        <i className="far fa-building fa-3x"></i>
                      </a>
                    </p>
                  )}
                </div>
                <div className="member-box-footer">
                  <div className="row member-box-footer-body">
                    <div className="col-md-12">
                      <p className="text-center" style={{ fontSize: "16px" }}>
                        <a href={`/${url}/member/${members[j].url}`} className="member-link">
                          {members[j].name}
                        </a>
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 text-center">
                      {members[j].isGolden === true && (
                        <p>
                          <span className="member-golden">Golden partner</span>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      return colsList;
    }

    for (let i = 0; i < Tabs; i++) {
      let columnMultiply = 1;

      membersList.push(
        <div className="row justify-content-md-center" key={i}>
          {renderCols(members, i, inRow)}
        </div>
      );
    }

    if (members.length > 0) {
      return membersList;
    } else {
      return (
        <h4
          style={{
            textAlign: "center",
            margin: "25px 0",
            fontWeight: "normal",
          }}
        >
          {`{...}`} <br />
          Members not found
        </h4>
      );
    }
  };

  render() {
    let memberList = this.state.loadingMembers ? (
      <Loading loaded={this.state.loadingMembers} />
    ) : this.state.members !== undefined ? (
      this.renderMembers(this.state.members, this.props.baseUrl.url)
    ) : (
      <h4 style={{ textAlign: "center", margin: "25px 0", fontWeight: "normal" }}>
        {`{...}`} <br />
        Members not found
      </h4>
    );

    return (
      <div className="container">
        <div className="col-lg-12 col-md-12 text-page-content" style={{ marginTop: "30px" }}>
          {this.props.content.title !== null && this.props.content.sectionOrder === false && (
            <React.Fragment>
              <h1 className="text-page-header">{this.props.content.title}</h1>
              <div className="title-page-divider"></div>
            </React.Fragment>
          )}

          {ReactHtmlParser(ReactHtmlParser(this.props.content.contentContent))}

          <Fragment>
            <div className="row">
              <div className="col-md-12 text-center">
                <h4>Search Member</h4>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                {this.state.loadingForm === false ? (
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row row">
                      <div className="col-md-4 mt-2">
                        <select
                          ref="firstName"
                          id="country"
                          onChange={this.handleChange.bind(this, "country")}
                          value={this.state.fields["country"]}
                          className="form-control"
                        >
                          <option value="" selected>
                            Choose country of BEPOLUX activity
                          </option>
                          {this.state.formFields.countryList.map((c, i) => (
                            <option value={c.url}>{c.value}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4 mt-2">
                        <select
                          value={this.state.fields.industry}
                          ref="industry"
                          id="industry"
                          onChange={this.handleChange.bind(this, "industry")}
                          value={this.state.fields["industry"]}
                          className="form-control"
                        >
                          <option value="" selected>
                            Choose industry
                          </option>
                          {this.state.formFields.industryList.map((c, i) => (
                            <option value={c.url}>{c.value}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-4 text-center mt-2">
                        <button className="btn btn-primary">SEARCH</button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <Loading loaded={this.state.loadingForm} />
                )}
              </div>
            </div>
          </Fragment>

          <hr />

          <div className="row mb-2">
            <div className="col-md-12 text-center">
              <h4>Members list</h4>
            </div>
          </div>

          {memberList}

          {this.props.content.contentShowdate === true && (
            <p className="small">
              Data aktualizacji:
              {new Intl.DateTimeFormat("pl-PL", {
                year: "numeric",
                month: "long",
                day: "2-digit",
              }).format(this.props.content.contentDate)}
            </p>
          )}
        </div>
      </div>
    );
  }

  async populateMemberListData() {
    this.setState({
      members: await clientConnectCustom("api/catalog/list", null),
      loadingMembers: false,
    });
  }

  async populateFieldsData() {
    this.setState({
      formFields: await clientConnectCustom("api/search/fields", null),
      loadingForm: false,
    });
  }
}

MembersList.propTypes = {
  content: PropTypes.object,
};

export default MembersList;
