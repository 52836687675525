import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { clientConnect, clientConnectCustom } from "../../client";
import Loading from "../shared/Loading";
import { accessToken, siteId, apiUrl2 } from "../../config";

export class MembersSignUp extends Component {
  static displayName = MembersSignUp.name;

  constructor(props) {
    super();
    this.state = {
      loading: false,
      status: "",
      message: "",
      fields: {
        name: "",
        legalStatus: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        vATId: "",
        officialFname: "",
        officialLname: "",
        officialPosition: "",
        officialPhone: "",
        officialEmail: "",
        contactFname: "",
        contactLname: "",
        contactPosition: "",
        contactPhone: "",
        contactEmail: "",
        numberOfEmployees: "",
        activityDescription: "",
        personFilling: "",
        catalogShortDescription: "",
        catalogCountries: [],
        catalogContactPerson: "",
        catalogWWW: "",
        catalogEmail: "",
        catalogCompanyTag: "",
        catalogLinkedin: "",
        catalogTwitter: "",
        catalogFacebook: "",
        catalogYouTube: "",
      },
      errors: {},
    };
  }

  async sendForm(model) {
    fetch(`${apiUrl2}api/member/signup`, {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-BBCMS-TOKEN": accessToken,
        "X-BBCMS-SITE": siteId,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.status);
        if (response.status === "OK") {
          this.setState({ status: true, loading: false });
          this.resetForm();
        } else if (response.status === "Error") {
          this.setState({ status: false, loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ status: false, loading: false });
      });
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Name
    if (fields["name"] === "") {
      formIsValid = false;
      errors["name"] = "Please enter company name.";
    }

    if (fields["legalStatus"] === "") {
      formIsValid = false;
      errors["legalStatus"] = "Please enter company legal status.";
    }

    if (fields["address"] === "") {
      formIsValid = false;
      errors["address"] = "Please enter company address.";
    }

    if (fields["zip"] === "") {
      formIsValid = false;
      errors["zip"] = "Please enter company address post code";
    }

    if (fields["city"] === "") {
      formIsValid = false;
      errors["city"] = "Please enter company address city";
    }

    if (fields["country"] === "") {
      formIsValid = false;
      errors["country"] = "Please enter company address country";
    }

    if (fields["vATId"] === "") {
      formIsValid = false;
      errors["vATId"] = "Please enter company VAT ID (VAT Number)";
    }

    if (fields["contactFname"] === "") {
      formIsValid = false;
      errors["contactFname"] = "Please enter contact person first name";
    }

    if (fields["contactLname"] === "") {
      formIsValid = false;
      errors["contactLname"] = "Please enter contact person last name";
    }

    if (fields["contactPosition"] === "") {
      formIsValid = false;
      errors["contactPosition"] = "Please enter contact person position";
    }

    if (fields["contactPhone"] === "") {
      formIsValid = false;
      errors["contactPhone"] = "Please enter contact person phone number";
    } else {
      if (typeof fields["contactPhone"] !== "undefined") {
        var pattern = new RegExp(/^[\+]?[0-9\b]+$/);
        if (!pattern.test(fields["contactPhone"])) {
          formIsValid = false;
          errors["contactPhone"] = "Please enter only number.";
        } else if (
          fields["contactPhone"].length <= 8 &&
          fields["contactPhone"].length > 16
        ) {
          formIsValid = false;
          errors["contactPhone"] = "Please enter valid phone number.";
        }
      }
    }

    if (fields["contactEmail"] === "") {
      formIsValid = false;
      errors["contactEmail"] = "Please enter contact person e-mail";
    } else {
      if (
        !fields["contactEmail"].match(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
      ) {
        formIsValid = false;
        errors["contactEmail"] = "Please enter valid email address.";
      }
    }

    if (fields["numberOfEmployees"] === "") {
      formIsValid = false;
      errors["numberOfEmployees"] = "Please enter company number of employees";
    } else {
      if (typeof fields["numberOfEmployees"] !== "undefined") {
        var pattern = new RegExp(/^[0-9\b]+$/);
        if (!pattern.test(fields["numberOfEmployees"])) {
          formIsValid = false;
          errors["numberOfEmployees"] = "Please enter only number.";
        } else if (fields["numberOfEmployees"].length < 1) {
          formIsValid = false;
          errors["numberOfEmployees"] = "Please enter proper vaule.";
        }
      }
    }

    if (fields["activityDescription"] === "") {
      formIsValid = false;
      errors["activityDescription"] = "Please enter company activity";
    }

    if (fields["personFilling"] === "") {
      formIsValid = false;
      errors["personFilling"] =
        "Please enter first and last name of person filling the application";
    }

    if (fields["catalogEmail"] === "") {
    } else {
      if (
        !fields["catalogEmail"].match(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
      ) {
        formIsValid = false;
        errors["catalogEmail"] = "Please enter valid email address.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleMultipleChange(field, e) {
    console.log(e.target.value);
    console.log(field);

    let fields = this.state.fields;
    const findIdx = fields[field].indexOf(e.target.value);
    console.log(findIdx);

    //fields[field] = e.target.value;

    let v = fields[field];

    if (findIdx > -1) {
      v.splice(findIdx, 1);
    } else {
      v.push(e.target.value);
    }

    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: v,
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true }, () => {
      if (this.handleValidation()) {
        const model = {
          name: this.state.fields.name,
          legalStatus: this.state.fields.legalStatus,
          address: this.state.fields.address,
          zip: this.state.fields.zip,
          city: this.state.fields.city,
          country: this.state.fields.country,
          vATId: this.state.fields.vATId,
          officialFname: this.state.fields.officialFname,
          officialLname: this.state.fields.officialLname,
          officialPosition: this.state.fields.officialPosition,
          officialPhone: this.state.fields.officialPhone,
          officialEmail: this.state.fields.officialEmail,
          contactFname: this.state.fields.contactFname,
          contactLname: this.state.fields.contactLname,
          contactPosition: this.state.fields.contactPosition,
          contactPhone: this.state.fields.contactPhone,
          contactEmail: this.state.fields.contactEmail,
          numberOfEmployees: this.state.fields.numberOfEmployees,
          activityDescription: this.state.fields.activityDescription,
          personFilling: this.state.fields.personFilling,
          catalogShortDescription: this.state.fields.catalogShortDescription,
          catalogCountries: this.state.fields.catalogCountries,
          catalogContactPerson: this.state.fields.catalogContactPerson,
          catalogWWW: this.state.fields.catalogWWW,
          catalogEmail: this.state.fields.catalogEmail,
          catalogCompanyTag: this.state.fields.catalogCompanyTag,
          catalogLinkedin: this.state.fields.catalogLinkedin,
          catalogTwitter: this.state.fields.catalogTwitter,
          catalogFacebook: this.state.fields.catalogFacebook,
          catalogYouTube: this.state.fields.catalogYouTube,
        };

        this.sendForm(model);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  resetForm = (e) => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
  };

  newCompany = (e) => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    this.setState({ loading: false });
    this.setState({ status: "" });
  };

  render() {
    return (
      <div class="col-lg-12 col-md-12 text-page-content">
        {this.props.content.contentTitle != null && (
          <React.Fragment>
            <h1 className="text-page-header">
              {this.props.content.contentTitle}
            </h1>
            <div className="title-page-divider"></div>
          </React.Fragment>
        )}

        {ReactHtmlParser(ReactHtmlParser(this.props.content.contentContent))}

        <div
          className="row justify-content-md-center"
          style={{ marginTop: "30px" }}
        >
          <div className="col-md-12">
            <h1>Become a BEPOLUX member</h1>

            <p>
              Bepolux offers its members an ambitious program of&nbsp;
              <u>networking events</u>&nbsp;such as:
            </p>
            <ul type="disc">
              <li>
                <strong>Business lunches</strong>&nbsp;with eminent Belgian and
                Polish guest speakers, experts in political, financial, economic
                and social issues, etc.
              </li>
              <li>
                <strong>B2B meetings&nbsp;</strong>with Polish companies
              </li>
              <li>
                <strong>Workshops and seminars</strong>&nbsp;on topics such as:
                HR and labor market in Poland, Energy and environment, Finance
                and Economy, etc.
              </li>
            </ul>

            <p>
              In order to become a BEPOLUX Member, please fill in the following
              form and please transfer your membership fee:{" "}
            </p>
            <ul type="disc">
              <li>300 € for companies with max 50 employees: </li>
              <li>500 € for companies with more than 50 employees, </li>
            </ul>
            <p>
              to account nr&nbsp;
              <strong>
                <u>BE 74 2100 8168 8907</u>
              </strong>
              &nbsp;of BEPOLUX, mentioning &quot;<u>Membership 2025</u>&quot;.
              <br />
              <br />
              <em>
                A free contribution of minimum 750 € will give you the
                privileged status of golden member and your company logo with
                link will appear during the current year on this BEPOLUX
                website:&nbsp;&nbsp;
              </em>
              <a href="http://www.bepolux.org/">
                <em>http://www.bepolux.org</em>
              </a>
            </p>
            <p>
              After receiving your application and transfer, your Membership
              will be activated to the next 12 months.
            </p>

            <h2 className="text-center mb-3 mt-3">
              BEPOLUX member application form
            </h2>

            {this.state.status === false && this.state.loading === false && (
              <div className="alert alert-danger" role="alert">
                {this.state.message}
              </div>
            )}

            {this.state.status === true && this.state.loading === false && (
              <div className="alert alert-success" role="alert">
                Thank you for submiting company to BEPOLUX. We will contact you
                shortly with further details.
                <p>&nbsp;</p>
                <p>
                  <button onclick={this.newCompany}>Add next company</button>
                </p>
              </div>
            )}

            {this.state.loading === false && this.state.status !== true ? (
              <>
                <p className="small text-center">
                  Please provide information about your company. Fields marked
                  with * are mandatory
                </p>
                <form onSubmit={this.handleSubmit}>
                  <h3 className="text-center">Company general information</h3>
                  <hr />
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="name"
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="Company name *"
                        onChange={this.handleChange.bind(this, "name")}
                        value={this.state.fields["name"]}
                      />
                      {this.state.errors.name && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["name"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="legalStatus"
                        id="legalStatus"
                        type="text"
                        className="form-control"
                        placeholder="Company legal status *"
                        onChange={this.handleChange.bind(this, "legalStatus")}
                        value={this.state.fields["legalStatus"]}
                      />
                      {this.state.errors.legalStatus && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["legalStatus"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="address"
                        id="address"
                        type="text"
                        className="form-control"
                        placeholder="Address *"
                        onChange={this.handleChange.bind(this, "address")}
                        value={this.state.fields["address"]}
                      />
                      {this.state.errors.address && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["address"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-2 mb-2">
                      <input
                        ref="zip"
                        id="zip"
                        type="text"
                        className="form-control"
                        placeholder="Post code *"
                        onChange={this.handleChange.bind(this, "zip")}
                        value={this.state.fields["zip"]}
                      />
                      {this.state.errors.zip && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["zip"]}
                        </div>
                      )}
                    </div>

                    <div className="form-group col-md-6 mb-2">
                      <input
                        ref="city"
                        id="city"
                        type="text"
                        className="form-control"
                        placeholder="City *"
                        onChange={this.handleChange.bind(this, "city")}
                        value={this.state.fields["city"]}
                      />
                      {this.state.errors.city && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["city"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="country"
                        id="country"
                        type="text"
                        className="form-control"
                        placeholder="Country *"
                        onChange={this.handleChange.bind(this, "country")}
                        value={this.state.fields["country"]}
                      />
                      {this.state.errors.country && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["country"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="vATId"
                        id="vATId"
                        type="text"
                        className="form-control"
                        placeholder="VAT ID (VAT number) *"
                        onChange={this.handleChange.bind(this, "vATId")}
                        value={this.state.fields["vATId"]}
                      />
                      {this.state.errors.vATId && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["vATId"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="numberOfEmployees"
                        id="numberOfEmployees"
                        type="text"
                        className="form-control"
                        placeholder="Number of empoloyees *"
                        onChange={this.handleChange.bind(
                          this,
                          "numberOfEmployees"
                        )}
                        value={this.state.fields["numberOfEmployees"]}
                      />
                      {this.state.errors.numberOfEmployees && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["numberOfEmployees"]}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <textarea
                        ref="activityDescription"
                        id="activityDescription"
                        type="text"
                        className="form-control"
                        placeholder="Company activity description *"
                        onChange={this.handleChange.bind(
                          this,
                          "activityDescription"
                        )}
                        value={this.state.fields["activityDescription"]}
                        rows="8"
                      ></textarea>
                      {this.state.errors.activityDescription && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["activityDescription"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <h3 className="text-center">Company representation</h3>
                  <hr />
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-4 mb-2">
                      <input
                        ref="officialFname"
                        id="officialFname"
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        onChange={this.handleChange.bind(this, "officialFname")}
                        value={this.state.fields["officialFname"]}
                      />
                      {this.state.errors.officialFname && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["officialFname"]}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-4 mb-2">
                      <input
                        ref="officialLname"
                        id="officialLname"
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        onChange={this.handleChange.bind(this, "officialLname")}
                        value={this.state.fields["officialLname"]}
                      />
                      {this.state.errors.officialLname && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["officialLname"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="officialPosition"
                        id="officialPosition"
                        type="text"
                        className="form-control"
                        placeholder="Position"
                        onChange={this.handleChange.bind(
                          this,
                          "officialPosition"
                        )}
                        value={this.state.fields["officialPosition"]}
                      />
                      {this.state.errors.officialPosition && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["officialPosition"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="officialPhone"
                        id="officialPhone"
                        type="text"
                        className="form-control"
                        placeholder="Phone number"
                        onChange={this.handleChange.bind(this, "officialPhone")}
                        value={this.state.fields["officialPhone"]}
                      />
                      {this.state.errors.officialPhone && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["officialPhone"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="officialEmail"
                        id="officialEmail"
                        type="text"
                        className="form-control"
                        placeholder="E-mail address"
                        onChange={this.handleChange.bind(this, "officialEmail")}
                        value={this.state.fields["officialEmail"]}
                      />
                      {this.state.errors.officialEmail && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["officialEmail"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <h3 className="text-center">Contact person details</h3>
                  <hr />
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-4 mb-2">
                      <input
                        ref="contactFname"
                        id="contactFname"
                        type="text"
                        className="form-control"
                        placeholder="First name *"
                        onChange={this.handleChange.bind(this, "contactFname")}
                        value={this.state.fields["contactFname"]}
                      />
                      {this.state.errors.contactFname && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["contactFname"]}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-md-4 mb-2">
                      <input
                        ref="contactLname"
                        id="contactLname"
                        type="text"
                        className="form-control"
                        placeholder="Last name *"
                        onChange={this.handleChange.bind(this, "contactLname")}
                        value={this.state.fields["contactLname"]}
                      />
                      {this.state.errors.contactLname && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["contactLname"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="contactPosition"
                        id="contactPosition"
                        type="text"
                        className="form-control"
                        placeholder="Position *"
                        onChange={this.handleChange.bind(
                          this,
                          "contactPosition"
                        )}
                        value={this.state.fields["contactPosition"]}
                      />
                      {this.state.errors.contactPosition && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["contactPosition"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="contactPhone"
                        id="contactPhone"
                        type="text"
                        className="form-control"
                        placeholder="Phone number *"
                        onChange={this.handleChange.bind(this, "contactPhone")}
                        value={this.state.fields["contactPhone"]}
                      />
                      {this.state.errors.contactPhone && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["contactPhone"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="contactEmail"
                        id="contactEmail"
                        type="text"
                        className="form-control"
                        placeholder="E-mail address *"
                        onChange={this.handleChange.bind(this, "contactEmail")}
                        value={this.state.fields["contactEmail"]}
                      />
                      {this.state.errors.contactEmail && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["contactEmail"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <h3 className="text-center">On line catalog details</h3>
                  <p className="text-center small">
                    Information provided in the fields below will be visible in
                    the on-line members catalog
                  </p>
                  <hr />
                  <div className="form-row row justify-content-md-center">
                    <div className="col-md-3">
                      Countries of BEPOLUX activities:
                    </div>
                    <div className="form-group col-md-5 mb-2">
                      <div class="form-check  form-check-inline">
                        <input
                          name="catalogCountries"
                          id="catalogCountries.be"
                          type="checkbox"
                          value="belgium"
                          selected={
                            !this.state.catalogCountries === undefined &&
                            this.state.catalogCountries.contains("belgium")
                              ? true
                              : false
                          }
                          onChange={this.handleMultipleChange.bind(
                            this,
                            "catalogCountries"
                          )}
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          for="catalogCountries.be"
                        >
                          Belgium
                        </label>
                      </div>

                      <div class="form-check  form-check-inline">
                        <input
                          name="catalogCountries"
                          id="catalogCountries.pl"
                          type="checkbox"
                          value="poland"
                          selected={
                            !this.state.catalogCountries === undefined &&
                            this.state.catalogCountries.contains("poland")
                              ? true
                              : false
                          }
                          onChange={this.handleMultipleChange.bind(
                            this,
                            "catalogCountries"
                          )}
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          for="catalogCountries.pl"
                        >
                          Poland
                        </label>
                      </div>

                      <div class="form-check  form-check-inline">
                        <input
                          name="catalogCountries"
                          id="catalogCountries.lu"
                          type="checkbox"
                          value="luxembourg"
                          selected={
                            !this.state.catalogCountries === undefined &&
                            this.state.catalogCountries.contains("luxembourg")
                              ? true
                              : false
                          }
                          onChange={this.handleMultipleChange.bind(
                            this,
                            "catalogCountries"
                          )}
                          className="form-check-input"
                        />
                        <label
                          className="form-check-label"
                          for="catalogCountries.lu"
                        >
                          Luxembourg
                        </label>
                      </div>

                      {this.state.errors.catalogCountries && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogCountries"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <textarea
                        ref="catalogShortDescription"
                        id="catalogShortDescription"
                        type="text"
                        className="form-control"
                        placeholder="Short description"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogShortDescription"
                        )}
                        value={this.state.fields["catalogShortDescription"]}
                        rows="8"
                      ></textarea>
                      {this.state.errors.catalogShortDescription && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogShortDescription"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogContactPerson"
                        id="catalogContactPerson"
                        type="text"
                        className="form-control"
                        placeholder="Contact person"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogContactPerson"
                        )}
                        value={this.state.fields["catalogContactPerson"]}
                      />
                      {this.state.errors.catalogContactPerson && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogContactPerson"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogWWW"
                        id="catalogWWW"
                        type="text"
                        className="form-control"
                        placeholder="Website address"
                        onChange={this.handleChange.bind(this, "catalogWWW")}
                        value={this.state.fields["catalogWWW"]}
                      />
                      {this.state.errors.catalogWWW && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogWWW"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogEmail"
                        id="catalogEmail"
                        type="text"
                        className="form-control"
                        placeholder="E-mail address"
                        onChange={this.handleChange.bind(this, "catalogEmail")}
                        value={this.state.fields["catalogEmail"]}
                      />
                      {this.state.errors.catalogEmail && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogEmail"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogCompanyTag"
                        id="catalogCompanyTag"
                        type="text"
                        className="form-control"
                        placeholder="Company tag"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogCompanyTag"
                        )}
                        value={this.state.fields["catalogCompanyTag"]}
                      />
                      {this.state.errors.catalogCompanyTag && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogCompanyTag"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogLinkedin"
                        id="catalogLinkedin"
                        type="text"
                        className="form-control"
                        placeholder="Linkedin profile"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogLinkedin"
                        )}
                        value={this.state.fields["catalogLinkedin"]}
                      />
                      {this.state.errors.catalogLinkedin && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogLinkedin"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogTwitter"
                        id="catalogTwitter"
                        type="text"
                        className="form-control"
                        placeholder="Twitter profile"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogTwitter"
                        )}
                        value={this.state.fields["catalogTwitter"]}
                      />
                      {this.state.errors.catalogTwitter && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogTwitter"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogFacebook"
                        id="catalogFacebook"
                        type="text"
                        className="form-control"
                        placeholder="Facebook page"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogFacebook"
                        )}
                        value={this.state.fields["catalogFacebook"]}
                      />
                      {this.state.errors.catalogFacebook && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogFacebook"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <input
                        ref="catalogYouTube"
                        id="catalogYouTube"
                        type="text"
                        className="form-control"
                        placeholder="YouTube channel"
                        onChange={this.handleChange.bind(
                          this,
                          "catalogYouTube"
                        )}
                        value={this.state.fields["catalogYouTube"]}
                      />
                      {this.state.errors.catalogYouTube && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogYouTube"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-8 mb-2">
                      <p>
                        I declare that the data provided in the above form is
                        correct and truthful and that I am the person authorized
                        to represent the company.
                      </p>
                      <p>
                        <strong>
                          By submitting the above form you agree to the BEPOLUX
                          membership rules.
                        </strong>
                      </p>
                      <input
                        ref="personFilling"
                        id="personFilling"
                        type="text"
                        className="form-control"
                        placeholder="First name and last name of person filling the form *"
                        onChange={this.handleChange.bind(this, "personFilling")}
                        value={this.state.fields["personFilling"]}
                      />
                      {this.state.errors.catalogYouTube && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i>{" "}
                          {this.state.errors["catalogYouTube"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center mt-2 mb-4">
                    <div className="form-group col-md-6">
                      <button className="btn btn-primary btn-block">
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </form>
              </>
            ) : (
              <Loading loaded={this.state.loaded} />
            )}
          </div>
        </div>

        {this.props.content.contentShowdate === true && (
          <p class="small">
            Data aktualizacji:
            {new Intl.DateTimeFormat("pl-PL", {
              year: "numeric",
              month: "long",
              day: "2-digit",
            }).format(this.props.content.contentDate)}
          </p>
        )}
      </div>
    );
  }
}

MembersSignUp.propTypes = {
  content: PropTypes.object,
};

export default MembersSignUp;
