import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect } from "../../client";
import Moment from "react-moment";

export class SectionNews extends Component {
  constructor(props) {
    super();
    this.state = {
      news: [],
      loadingNews: true,
    };
  }

  componentDidMount() {
    this.populateNewsData("en-GB", this.props.sectionContent.contentSecondary, 3);
  }

  styleSection = (background) => {
    return {
      backgroundColor: background,
    };
  };

  rowStyles = () => {
    return {
      marginTop: "20px",
      marginBottom: "20px",
    };
  };

  static renderNews(news) {
    let url = "news";

    return (
      <>
        <div className="row justify-content-md-center">
          <div className="col-md-12">
            {news.length > 0 ? (
              news.map((news, i) => (
                <div className="row" key={i}>
                  <div className="col-md-12 news-slice">
                    <h3>{news.title}</h3>
                    <h5>
                      <a href={`/${url}/category/${news.categoryUrl}`}>
                        <i className="fa fa-sitemap"></i>
                        <span className="pl-2">{news.categoryName}</span>
                      </a>
                    </h5>
                    {ReactHtmlParser(news.abstract)}
                    {news.newsShowDate && (
                      <p className="small">
                        Published: <Moment format="DD.MM.YYYY">{news.newsDate}</Moment>
                      </p>
                    )}
                    <p style={{ marginBottom: "0px" }}>
                      <a href={`/${url}/news/${news.url}`} className="btn btn-default">
                        read more...
                      </a>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <>
                <p className="text-center" style={{ fontSize: "52px" }}>{`{...}`}</p>
                <p className="text-center">no news available</p>
              </>
            )}
          </div>
        </div>
      </>
    );
  }

  render() {
    let newsList = this.state.loadingNews ? <p className="text-center">Loading...</p> : SectionNews.renderNews(this.state.news);

    return (
      <div className={`col-lg-${this.props.sectionContent.widthInColumns} col-md-${this.props.sectionContent.widthInColumns} xs-text-center`}>
        {ReactHtmlParser(this.props.sectionContent.content)}

        {newsList}
      </div>
    );
  }

  async populateNewsData(lng, url, itemsNum) {
    if (url) {
      this.setState({ news: await clientConnect("api/news/categorylist", `${lng}/${url}/0/${itemsNum}`), loadingNews: false });
    } else {
      this.setState({ news: await clientConnect("api/news/list", `${lng}/0/${itemsNum}`), loadingNews: false });
    }
  }
}

SectionNews.propTypes = {
  sectioncontents: PropTypes.object,
};

export default SectionNews;
