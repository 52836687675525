import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { Gmaps, Marker, InfoWindow, Circle } from "react-gmaps";

// const coords = {
//   lat: 50.851111,
//   lng: 4.40186442,
// };

const params = { v: "3.exp", key: "AIzaSyADlIORRPe1mjagjmBHREF-_rEZXLrkaYA" };

export class SectionMap extends Component {
  constructor(props) {
    super();
  }

  onMapCreated(map) {
    map.setOptions({
      disableDefaultUI: true,
    });
  }

  onDragEnd(e) {
    console.log("onDragEnd", e);
  }

  onCloseClick() {
    console.log("onCloseClick");
  }

  onClick(e) {
    console.log("onClick", e);
  }

  render() {
    let coordsArray = this.props.sectionContent.contentSecondary.split(";");

    return (
      <>
        <div className={`col-lg-${this.props.sectionContent.widthInColumns} col-md-${this.props.sectionContent.widthInColumns} xs-text-center`}>
          {ReactHtmlParser(this.props.sectionContent.content)}
        </div>

        <Gmaps
          width={"100%"}
          height={"300px"}
          lat={coordsArray[0]}
          lng={coordsArray[1]}
          zoom={16}
          loadingMessage={"Loading map"}
          params={params}
          onMapCreated={this.onMapCreated}
        >
          <Marker lat={coordsArray[0]} lng={coordsArray[1]} draggable={true} onDragEnd={this.onDragEnd} />
          {/* <InfoWindow lat={coords.lat} lng={coords.lng} content={"BEPOLUX"} onCloseClick={this.onCloseClick} /> */}
          {/* <Circle lat={coords.lat} lng={coords.lng} radius={500} onClick={this.onClick} /> */}
        </Gmaps>
      </>
    );
  }
}

SectionMap.propTypes = {
  sectioncontents: PropTypes.object,
};

export default SectionMap;
