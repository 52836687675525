import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect, clientConnectCustom } from "../../client";
import Loading from "../shared/Loading";
import { accessToken, siteId, apiUrl } from "../../config";

export class NewsletterForm extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      status: "",
      message: "",
      fields: { email: "" },
      errors: {},
    };
  }

  async sendForm(model) {
    fetch(`${apiUrl}api/newsletter`, {
      method: "POST",
      body: JSON.stringify(model),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-BBCMS-TOKEN": accessToken,
        "X-BBCMS-SITE": siteId,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "OK") {
          this.setState({ status: true, loading: false });
          this.resetForm();
        } else if (response.status === "Exists") {
          this.setState({ status: false, loading: false, message: "That email is already subscribed to the newsletter" });
          this.resetForm();
        } else if (response.status === "Error") {
          this.setState({ status: false, loading: false, message: "General error (Code: 21)" });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ status: false, loading: false, message: "General error (Code: 20)" });
      });
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //email
    if (fields["email"] === "") {
      formIsValid = false;
      errors["email"] = "Please enter your email address.";
    } else {
      if (
        !fields["email"].match(
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({
      fields: {
        ...this.state.fields,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ loading: true }, () => {
      if (this.handleValidation()) {
        const model = {
          email: this.state.fields.email,
        };

        this.sendForm(model);
      } else {
        this.setState({ loading: false });
      }
    });
  };

  resetForm = (e) => {
    // document.getElementById("newsletterForm").reset();
    // this.newsletterForm.reset();

    // let fields = this.state.fields;
    // Array.from(document.querySelectorAll("input")).forEach((input) => {
    //   input.value = "";
    //   fields[input.id] = "";
    //   this.setState({
    //     fields: {
    //       ...this.state.fields,
    //       field: "",
    //     },
    //   });
    // });
    let fields = this.state.fields;

    this.setState({
      fields: {
        ...this.state.fields,
        email: "",
      },
    });
  };

  render() {
    return (
      <div className="row justify-content-md-center" style={{ marginTop: "30px" }}>
        <div className="col-md-12">
          {this.state.status === false && this.state.loading === false && (
            <div className="alert alert-danger" role="alert">
              {this.state.message}
            </div>
          )}

          {this.state.status === true && this.state.loading === false && (
            <div className="alert alert-success" role="alert">
              Thank you for subscribing our newsletter, please check you mailbox and click on confirmation link.
            </div>
          )}

          {this.state.loading === false ? (
            <>
              <p className="small">
                Fill out the form below and by clicking the "Submit" button, you consent to the sending of the newsletter to the e-mail address provided by
                BEPOLUX and you declare that you have read the information clause.
              </p>
              {this.props.formType === "inline" ? (
                <form onSubmit={this.handleSubmit} className="form-inline" ref={(e) => (this.newsletterForm = e)} id="newsletterForm">
                  <input
                    ref="email"
                    id="email"
                    type="text"
                    className="form-control mb-2 mr-sm-2 col-md-8"
                    placeholder="E-mail address *"
                    onChange={this.handleChange.bind(this, "email")}
                    value={this.state.fields["email"]}
                  />
                  <button className="btn btn-blue mb-2 mr-sm-2" style={{ padding: "0.25rem 1rem", fontSize: "18px" }}>
                    SUBMIT
                  </button>

                  {this.state.errors.email && (
                    <div style={{ color: "red" }}>
                      <i className="fas fa-exclamation-triangle"></i> {this.state.errors["email"]}
                    </div>
                  )}
                </form>
              ) : (
                <form onSubmit={this.handleSubmit} ref={(e) => (this.newsletterForm = e)} id="newsletterForm">
                  <div className="form-row row justify-content-md-center">
                    <div className="form-group col-md-6 mb-2">
                      <input
                        ref="email"
                        id="email"
                        type="text"
                        className="form-control"
                        placeholder="E-mail address *"
                        onChange={this.handleChange.bind(this, "email")}
                        value={this.state.fields["email"]}
                      />
                      {this.state.errors.email && (
                        <div style={{ color: "red" }} className="mt-2">
                          <i className="fas fa-exclamation-triangle"></i> {this.state.errors["email"]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-row row justify-content-md-center mt-2 mb-4">
                    <div className="form-group col-md-6">
                      <button className="btn btn-primary btn-block">SUBMIT</button>
                    </div>
                  </div>
                </form>
              )}
            </>
          ) : (
            <Loading loaded={this.state.loaded} />
          )}
        </div>
      </div>
    );
  }
}

NewsletterForm.propTypes = {
  content: PropTypes.object,
};

export default NewsletterForm;
