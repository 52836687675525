import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import MembersDetails from "../members/MembersDetails";
import MembersList from "../members/MembersList";
import MembersSignUp from "../members/MembersSignUp";

export class ContentMembers extends Component {
  render() {
    return (
      <div className="container">
        <div className="row" style={{ marginBottom: "30px" }}>
          {(() => {
            switch (this.props.baseUrl.mode) {
              case "member":
                return <MembersDetails member={this.props.baseUrl.itemurl} baseUrl={this.props.baseUrl} content={this.props.content} />;
              case "signup":
                return <MembersSignUp member={this.props.baseUrl.itemurl} baseUrl={this.props.baseUrl} content={this.props.content} />;
              default:
                return <MembersList baseUrl={this.props.baseUrl} content={this.props.content} title={this.props.title} />;
            }
          })()}
        </div>
      </div>
    );
  }
}

ContentMembers.propTypes = {
  content: PropTypes.object,
};

export default ContentMembers;
