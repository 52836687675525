import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import { clientConnect } from "../../client";
import Moment from "react-moment";
import Calendar from "../calendar/Calendar";
import Container from "reactstrap/lib/Container";
import moment from "moment";
import("moment/locale/en-gb").then();

export class DayDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      events: [],
      loadingEvents: true,
    };
  }

  componentDidMount() {
    if (!this.props.content.content) {
      this.populateEventsData(this.props.day);
    } else {
      this.populateEventsData(this.props.day, this.props.content.content);
    }
  }

  render() {
    return (
      <>
        <div
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            paddingTop: "30px",
          }}
        >
          <Container>
            <div className="row">
              <div className="col-12 xs-text-center">
                <div className="h1" style={{ textAlign: "center" }}>
                  {this.props.content.title}
                </div>
                <div className="title-page-divider">&nbsp;</div>
              </div>
            </div>
            <div style={{ marginTop: "40px" }}></div>
            <div className="h1">{moment(this.props.day).format("DD MMMM YYYY")}</div>
            {!this.state.loadingEvents ? (
              <>
                {this.state.events.length > 0 ? (
                  this.state.events.map((event, i) => (
                    <div
                      style={{
                        borderBottom: "1px solid #051E38",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                      key={i}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <h3>{event.title}</h3>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <i className="fas fa-clock"></i> <strong>Start:</strong> <Moment format="DD.MM.YYYY">{event.dateStart}</Moment> at{" "}
                            <Moment format="HH:mm">{event.dateStart}</Moment>
                            <br />
                            {event.isOnline === true ? (
                              <span>
                                <i className="fas fa-globe"></i> <strong>Online meeting</strong>
                              </span>
                            ) : (
                              <span>
                                <i className="fas fa-location-arrow"></i> <strong>Place:</strong> {event.place}, {event.city}
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <a href={`/${this.props.baseUrl.url}/event/${event.url}`} className="btn btn-secondary">
                            read more...
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <>
                    <p className="text-center" style={{ fontSize: "52px" }}>{`{...}`}</p>
                    <p className="text-center">no upcomming events</p>
                  </>
                )}
                <div className="row mt-4">
                  <div className="col-md-12 text-center">
                    <a href={`/${this.props.baseUrl.url}`}>go back to the calendar</a>
                  </div>
                </div>
              </>
            ) : (
              <p className="text-center">Loading...</p>
            )}
          </Container>
        </div>
      </>
    );
  }

  async populateEventsData(day, url) {
    if (url) {
      this.setState({
        events: await clientConnect("api/event/day", `${url}/${day}`),
        loadingEvents: false,
      });
    } else {
      this.setState({
        events: await clientConnect("api/event/day", day),
        loadingEvents: false,
      });
    }
  }
}

export default DayDetails;
