import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
//import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from "react-html-parser";
import EventDetails from "../event/EventDetails";
import DayDetails from "../event/DayDetails";
import EventList from "../event/EventList";

export class ContentCalendar extends Component {
  render() {
    return (
      <div className="container">
        <div className="row" style={{ marginBottom: "30px" }}>
          <div className="col-lg-12 col-md-12 text-page-content">
            {(() => {
              switch (this.props.baseUrl.mode) {
                case "event":
                  return <EventDetails event={this.props.baseUrl.itemurl} baseUrl={this.props.baseUrl} />;
                case "day":
                  return <DayDetails day={this.props.baseUrl.itemurl} baseUrl={this.props.baseUrl} content={this.props.content} />;
                case "category":
                  return <EventList baseUrl={this.props.baseUrl} content={this.props.content} categoryUrl={this.props.baseUrl.itemurl} />;
                default:
                  return <EventList baseUrl={this.props.baseUrl} content={this.props.content} />;
              }
            })()}
          </div>
        </div>
      </div>
    );
  }
}

ContentCalendar.propTypes = {
  content: PropTypes.object,
};

export default ContentCalendar;
